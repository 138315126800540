
//excavation group pages

.circle-hardscapes {
  border-radius: 50%;
  
  background: $icandigit-red;
  position: relative;
  padding: 7px;

  img{
  	position: relative;

  }
}

body.hardscapes{
  .top-bar-section ul li.active a, .top-bar-section ul li.active a span, .top-bar-section li.active:not(.has-form) a:not(.button){
  color: $icandigit-red;
  border-bottom: 3px solid $icandigit-red;
  }
}