
#home-1{
	background: url(../../images/home/home-1-bg.jpg)
	// background: url(../../images/home/home-2-bg.jpg)
	// top / 100% /* position / size */
    no-repeat                /* repeat */; 
	h1{

		@media #{$small-only} { 
			font-size: 1.5em;
			margin-top: 40px;
			margin-bottom: 60px;
		}
		color:#fff;
		text-align: center;
		span{
			font-weight: 900;
			text-transform: uppercase;
		}
		/* tag: */
		// line-height: 97px;
		text-shadow: 1px 1px 2px #030303;
		margin-top:  80px;
		margin-bottom: 80px;
		


	}
	height: 100vh;
	margin-top: -45px;

	.row section#section-1{
		margin-top:10%;
	}
}

body.home{
  .top-bar-section ul li.active a{
  	border-bottom: 3px solid $icandigit-blue;
  }
  .top-bar-section ul li.active a span{
    color: $icandigit-blue;	
  
  }
}


.container {
  width: 15%;
  // height:300px;
  @media #{$small-only} { 
	width: 30%;
	margin-top: 20px;
  }
  margin: 0 auto;
  // background: blue;
}

.circle {
  border-radius: 50%;
  // width: 100%;
  // padding-bottom: 100%;
  background: white;
  position: relative;
  padding: 7px;

  img{
  	position: relative;
  	// width:90%
  }
}

.home-cta{
	a.button{
		width: 200px;
	}
}


#home-services{
	margin-top: 30px;
	margin-bottom: 20px;
	h2{
		font-size: 3em;
		font-weight: 400;
		color:#8B572A;
	}
	h3{
		//font-family: $merriweather;
		color: $icandigit-grey;
		font-size: 1.75em;

	}
	h3.sub{
		margin-top: 10px;
	}
	h4{
		text-transform: capitalize;
		font-size: 1.5rem;
	}
	h4.sub{
		color: $icandigit-grey;
		font-size: 1.25rem;	
		text-transform: none;
		font-family: $merriweather;
		a{
			color:$icandigit-blue;

			&:hover{
				text-decoration: underline;
			}
		}
	}

	ul.services{
		margin-top:50px;
		// li.circle{background: #000;}

		li{

			a.service-link{
				display: block;
				&:hover{
					@include single-transition(all, 200ms, ease-in-out);
					background: #e8e8e8;
					
					// h4, span.ss-icon{color: $icandigit-grey;
					// 	@include transition(all, 200ms, ease-in-out);}
			

					// div.circle{
					// 	background: none;
					// 	@include transition(all, 200ms, ease-in-out);
					//}
					

				}

			}

			.container{
				width: 50%;
			}
			ul li.service-arrow{
				margin-top: 10px;
			}

			span.ss-icon{
				font-size:1.25rem;
			}
		}

		
		li.trees{
			h4, span.ss-icon{color: $icandigit-green;}
			

			div.circle{
				background: $icandigit-green;
			}
			
		}

		li.excavation{
			h4, span.ss-icon{color: $icandigit-orange;}
			div.circle{
				background: $icandigit-orange;
			}
		}

		li.landscapes{
			h4, span.ss-icon{color: $icandigit-purple;}
			div.circle{
				background: $icandigit-purple;
			}
		}

		li.hardscapes{
			h4, span.ss-icon{color: $icandigit-red;}
			div.circle{
				background: $icandigit-red;
			}
		}		
	}
	ul.sub-services{
		margin-top: 20px;
		margin-left: 0px;
		li{
			text-transform: capitalize;
			list-style: none;
			color: $icandigit-grey;
			letter-spacing: 1.17px;
			line-height: 22px;
		}
	}
}

div#home-about{
	//background-color: rgba(151,151,151,.125);
	margin-bottom: -20px;
	padding: 40px 0px 20px 0px;

	p{
		color:$icandigit-grey;
	}
}

div#homeQuote-1, div#homeQuote-2{
	background-color: rgba(151,151,151,.125);
	color:$icandigit-grey;
	padding: 50px 0px 20px 20px;
	text-align: left;
	p{
		font-size: 1.5em;
		font-weight: bold;
		margin-left: 90px;
		position: relative;
		color:$icandigit-grey;

	}
	span.quote{
			float: left;
			position: relative;
			margin-left: 20px;
			top:-10px;
			font-size: 6em;
			line-height: 1em;
			opacity: .25;
			font-weight: 100;
			
		}
}

div#homeQuote-2{
	margin-bottom: 80px;
}

table#homeBene{
	border: none;
	a{
		color: $icandigit-blue;
	}
	tr{
		background: #fff;
	}
	td.labely{
		text-align: right;
		color: $icandigit-lt-brown;
		font-size: 1.5em;
		font-weight: 600;
		vertical-align: top;
  		padding-top: 14px;
  		font-family: $raleway;
	}
}

// scale
// @mixin scale($scale) {
// 	 @include transform(scale($scale));
// } 
// // translate
// @mixin translate ($x, $y) {
//    @include transform(translate($x, $y));
// }

// //ease in
// @mixin translate ($x, $y) {
//    @include transform(translate($x, $y));
// }

// @mixin animation-name($name...) {
//   -o-animation-name: $name;
//   -moz-animation-name: $name;
//   -webkit-animation-name: $name;
//   animation-name: $name;
// }

// @mixin keyframes($animation-name) {
//   @-webkit-keyframes $animation-name {
//     @content;
//   }
//   @-moz-keyframes $animation-name {
//     @content;
//   }
//   @keyframes $animation-name {
//     @content;
//   }
// }




// @mixin transform($transforms) {
// 	   -moz-transform: $transforms;
// 	     -o-transform: $transforms;
// 	    -ms-transform: $transforms;
// 	-webkit-transform: $transforms;
//           transform: $transforms;
// }

// @mixin animation($animation...) {
//   -o-animation: $animation;
//   -moz-animation: $animation;
//   -webkit-animation: $animation;
//   animation: $animation;
// }



// .bounce {
//   // @include animation(bounce 2s infinite);
  
// }