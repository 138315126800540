//colors
$oyc-blue:rgba(32,48,79,1);
$oyc-blue2:rgba(9,89,118,1);
$oyc-blue3:rgba(21,141,184,1);

$icandigit-blue:rgba(139,196,198,1);
$icandigit-orange:rgba(239,120,14,1);
$icandigit-green:rgba(106,152,47,1);
$icandigit-purple:rgba(146,105,179,1);
$icandigit-red:rgba(172,61,61,1);
$icandigit-grey:rgba(151,151,151,1);
$icandigit-lt-brown:rgba(139,87,42,1);



//fonts
$raleway: 'Raleway', sans-serif;
$merriweather: 'Merriweather', serif;


// scale
// @mixin scale($scale) {
// 	 @include transform(scale($scale));
// } 
// // translate
// @mixin translate ($x, $y) {
//    @include transform(translate($x, $y));
// }

// //ease in
// @mixin transition($transition-property, $transition-time, $method) {
//     -webkit-transition: $transition-property $transition-time $method;
//     -moz-transition: $transition-property $transition-time $method;
//     -ms-transition: $transition-property $transition-time $method;
//     -o-transition: $transition-property $transition-time $method;
//     transition: $transition-property $transition-time $method;
// }

// @mixin animation-name($name...) {
//   -o-animation-name: $name;
//   -moz-animation-name: $name;
//   -webkit-animation-name: $name;
//   animation-name: $name;
// }

// @mixin keyframes($animation-name) {
//   @-webkit-keyframes $animation-name {
//     @content;
//   }
//   @-moz-keyframes $animation-name {
//     @content;
//   }
//   @keyframes $animation-name {
//     @content;
//   }
// }




// @mixin transform($transforms) {
// 	   -moz-transform: $transforms;
// 	     -o-transform: $transforms;
// 	    -ms-transform: $transforms;
// 	-webkit-transform: $transforms;
//           transform: $transforms;
// }

// @mixin animation($animation...) {
//   -o-animation: $animation;
//   -moz-animation: $animation;
//   -webkit-animation: $animation;
//   animation: $animation;
// }