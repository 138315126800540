
//trees group pages

.circle-trees {
  border-radius: 50%;
  // width: 100%;
  // padding-bottom: 100%;
  background: $icandigit-green;
  position: relative;
  padding: 7px;

  img{
  	position: relative;
  	// width:90%
  }
}

body.trees{
  .top-bar-section ul li.active a, .top-bar-section ul li.active a span, .top-bar-section li.active:not(.has-form) a:not(.button){
  color: $icandigit-green;
  border-bottom: 3px solid $icandigit-green;
  }
}


