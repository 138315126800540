
//excavation group pages

.circle-landscapes {
  border-radius: 50%;
  
  background: $icandigit-purple;
  position: relative;
  padding: 7px;

  img{
  	position: relative;

  }
}

body.landscapes{
  .top-bar-section ul li.active a, .top-bar-section ul li.active a span, .top-bar-section li.active:not(.has-form) a:not(.button){
  color: $icandigit-purple;
  border-bottom: 3px solid $icandigit-purple;
  }
}

